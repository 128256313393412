export interface UIModulesListProps {
  focusSearchInput?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UIModulesList = (props: UIModulesListProps) => {
  return null;
};

export default UIModulesList;
